import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import HeroGrid from "../components/grid/heroGrid"
import Divider from "../components/divider/divider"

const heroItems = [
  {
    title: "Blog",
    description:
      "Stay informed and get all the latest news, strategies, and digital developments.",
    btnLabel: "View Blog",
    btnHref: "/resources/blog",
  },
  {
    title: "Insights",
    description:
      "Unlock digital transformation insights through comprehensive case studies, research reports, and more.",
    btnLabel: "View Insights",
    btnHref: "/resources/insights",
  },
  {
    title: "Downloads",
    description:
      "Find and download all downloadable resources. Includes free templates, guides, and more.",
    btnLabel: "View Downloads",
    btnHref: "/resources/downloads",
  },
]

const description =
  "Learn more on digital transformation with our resources spread througout our Blog, Insights, and Downloads. Why? Need all the latest updates and developments? Need comprehensive insights? Need actionable guides, templates, and more?"

const ResourcesPage = () => {
  return (
    <Layout>
      <Seo title="Resources" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Resources</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
        <div className="mt-5 mb-5 pt-5 pb-5">
          <HeroGrid items={heroItems} />
        </div>
      </section>
    </Layout>
  )
}

export default ResourcesPage
